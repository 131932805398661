.floor {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    font-size: 56px;
    line-height: 56px;

    .floor-description {
        grid-column-start: 1;
        grid-column-end: span 3;
        grid-row-start: 1;
        grid-row-end: span 2;
        margin: 0.5px;
    }

    .floor-description-background {
        background: white;
        border-inline-end: 1px solid black;
        transform: translateX(1px);
    }

    .floor-description-text {
        padding: 60px 56px;

        .floor-description-lines {
            padding-inline-start: 35px
        }

        .language-switcher-container {
            position: relative;

            & > *:not(:first-child) {
                position: absolute;
                top: 0;
            }
        }
    }

    .floor-direction {
        place-self: center;
    }

    .floor-number {
        place-self: center;
        font-size: 95px;
        line-height: 95px;
    }
}
