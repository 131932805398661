@use '../../variables' as vars;

.product-intro-v1 {
    height: 100%;
    position: relative;
    background: white;

    & > * {
        position: absolute;
        top: 0;
    }

    .text-container {
        @include vars.hoi-font;

        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        font-size: 188px;
        line-height: 90%;

        .text {
            width: 100%;
            padding: 66px;
        }
    }
}
