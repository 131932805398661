.Headline {
    position: relative;
    &-container {
        position: absolute;
        padding-left: 5px;
        padding-top: 22px;
        width: 600px;
    }
    &.theme-light {
        * {
            color: #fff;
        }
    }

    &.theme-dark {
        * {
            color: #000;
        }
    }
}
