.CityReplay {
    .Step {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid red;
    }

    .Step-bottom {
        position: absolute;
        bottom: 0
    }

    .BodyCopy-Container {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 80%;
        border: 1px solid red;
    }
}
