@use '../../../../styles/tools';

.c-title-block {
	position: relative;
	margin-left: tools.figma-to-vw(6);
	background: #FFF;
	color: #000;

	&::after {
		content: "";
		position: absolute;
		right: tools.figma-to-vw(-60);
		bottom: 0;
		left: tools.figma-to-vw(-60);
		z-index: 1;
		height: 1px;
		background-color: rgba(#000, 0.8);
	}
}

.c-title-block__title {
	transform: translate3d(tools.figma-to-vw(2), tools.figma-to-vw(10), 0);

	&.chinese-first-line {
		margin-top: tools.figma-to-vw(4);
	}

	.u-line-height-7xl {
		line-height: 6.8vh;
	}

	.c-codification__text,
	.c-codification__character {
		//line-height: 0;
		//line-height: 0.84;
	}

	.c-codification__character {
		//line-height: 0;/**/
		//line-height: 0.84;
	}
}

.c-title-block__subtitle {
	width: 90%;
	margin-top: tools.figma-to-vw(154);
	color: #000;
}

.s-dark-theme {
	.c-title-block {
		background: #000;
		color: #FFF;

		&::after {
			background-color: rgba(#FFF, 0.8);
		}
	}

	.c-title-block__subtitle {
		color: #FFF;
	}
}
