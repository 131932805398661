.NBYMenu {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 78%;
    height: 86%;

    z-index: 999;
    transform: translate(-50%, -50%);

    &-content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        overflow: hidden;
    }

    &-chapter {
        display: fixed;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 5% 5%;
        justify-content: space-between;
        flex-direction: column;
    }

    header {
        font-size: 2.5vw;
        line-height: 2.8vw;
        padding: 1vw 1vw 3vw 1vw;
        width: 80%;
        font-family: "Helvetica";

        h2 {
            font-size: 2vw;
            opacity: .6;
        }
    }

    &-items {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    &-swoosh {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        img {
            position: absolute;
            left: 30%;
            bottom: -10%;
            width: 110%;
        }
    }
}
