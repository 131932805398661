@use '../variables' as vars;

.ticker {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .ticker-content-container {
        @include vars.hoi-font;

        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        height: 100%;

        .ticker-sample {
            white-space: pre;
            height: 100%;
            display: flex;
        }

        .ticker-item {
            padding-top: 20px;
            overflow: hidden;
            height: 100%;
            display: flex;
            align-items: center;
            margin: 0 20px;
        }

        .ticker-icon,
        .ticker-media {
            padding-bottom: 20px;
        }

        .ticker-media-image {
            max-height: 100%;
        }
    }
}
