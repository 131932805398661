.PodIntroLogo {
    position: absolute;
    z-index: 9999;

    &-split-left {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;

        img {
            height: 50%;
            object-fit: contain;
            width: 100% !important;
            position: absolute;
            top: 50%;
            left: 100%;
            transform: translate(-50%,-50%)
        }
    }
    &-split-right {
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        height: 100%;

        img {
            height: 50%;
            object-fit: contain;
            width: 100% !important;
            position: absolute;
            top: 50%;
            left: 0%;
            transform: translate(-50%,-50%);
        }
    }
}
