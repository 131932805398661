.FeatureCard {
    position: absolute;
    width: 100%;
    height: 100%;

    &-cover {
        position: absolute;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-caption {
        width: 100%;
        position: absolute;
        bottom: 0%;
        background: black;
        min-height: 100px;
        z-index: 9;

        * {
            color: white;
        }
    }
}
