@use "../tools/index" as tools;

:root {
	// font-family
	--font-f-primary: "COMP_FUTURA";
	--font-f-secondary: "NikeHelvetica";

	// font-weight
	--font-w-bold: 800;
	--font-w-medium: 600;
	--font-w-normal: 400;

	// font-size
	--font-s-7xl: #{tools.figma-to-vw(150)};
	--font-s-6xl: #{tools.figma-to-vw(100)};
	--font-s-5xl: #{tools.figma-to-vw(85)};
	--font-s-4xl: #{tools.figma-to-vw(68)};
	--font-s-3xl: #{tools.figma-to-vw(63)};
	--font-s-2xl: #{tools.figma-to-vw(56)};
	--font-s-xl: #{tools.figma-to-vw(50)};
	--font-s-lg: #{tools.figma-to-vw(36)};
	--font-s-md: #{tools.figma-to-vw(30)};
	--font-s-sm: #{tools.figma-to-vw(20)};
	--font-s-xs: #{tools.figma-to-vw(18)};
	--font-s-2xs: #{tools.figma-to-vw(16)};
	--font-s-3xs: #{tools.figma-to-vw(14)};
	--font-s-4xs: #{tools.figma-to-vw(10)};

	// line-height
	--line-height-7xl: #{tools.figma-to-vw(150)};
	--line-height-6xl: #{tools.figma-to-vw(100)};
	// --line-height-5xl: #{tools.figma-to-vw(85)};
	// --line-height-4xl: #{tools.figma-to-vw(68)};
	// --line-height-3xl: #{tools.figma-to-vw(63)};
	// --line-height-2xl: #{tools.figma-to-vw(56)};
	--line-height-xl: #{tools.figma-to-vw(55)};
	// --line-height-md: #{tools.figma-to-vw(30)};
	// --line-height-sm: #{tools.figma-to-vw(20)};
	--line-height-xs: #{tools.figma-to-vw(27)};
	--line-height-2xs: #{tools.figma-to-vw(24)};
	--line-height-3xs: #{tools.figma-to-vw(20)};

	// line-height
	--line-height-default: 1;
	// --line-height-default: 1.25;
}

.OVERRIDE_LINEHEIGHT {
	* {
		line-height: .85em !important;
	}
}