.Demo {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    video, img {
        height: 100%;
        width: 100%;
        //object-fit: contain;
        object-fit: cover;
        object-position: center center;

        //&.landscape {
            //height: unset;
            //width: 100%;
        //}
    }
}