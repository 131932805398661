#PIXEL-CUTOUT{
    .overlay-inner-wrapper {
        width: 85%;
        height: 80%;
    }

    h2 {
        font-weight: 500;
    }

    .swatch-group:nth-child(2) {
        top: -20%;
    }

    .swatch-list {
      height: calc(100% - var(--lls-cs-cutout-unit));
      left: var(--lls-cs-cutout-unit);
      transition: all 1s ease;

      .swatch-item {
        border-width: var(--lls-cs-cutout-border-thick);
      }

      .swatch-group .swatch-item:not(:first-child) {
        border-top-width: 0;
        margin-top: 0;
      }
    }

    @media screen and (max-width: 1000px) {
        h2 {
            font-size: 32px;
        }

        .overlay-inner-wrapper {
            width: 80%;
            height: 86%;
        }

        .swatch-group:nth-child(2) {
            top: 0%;
        }

        .swatch-item {
            width: 35px!important;
            height: 35px!important;
        }
    }
}
