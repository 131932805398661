.BodyCopy {
    position: absolute;
    width: 60%;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 0%;
    transform: translate(0%, -50%);

    &-container {
        position: absolute;
        width: 60%;
        height: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        left: 0%;
        transform: translate(0%, -50%);
    }

    &.theme-light {
        * {
            color: #fff;
        }
    }

    &.theme-dark {
        * {
            color: #000;
        }
    }
}
