.AppText {
    position: absolute;
    top: 30%;
    left: 1.30208333vh;

    &-supertitle {
        font-family: "helvetica-neue-medium";
        font-size: 1.5625vh;
        line-height: 1.6vh;
        margin-top: 1.5vh;
    }
}
