@use '../../../../styles/tools';

.c-container {
	position: relative;
	padding: tools.figma-to-vw(60);
	overflow: hidden;
	font-family: var(--font-f-secondary);
	background: #FFF;
	// transition: background 400ms ease-in-out;
}


// DELETE: once grid is available
.c-container__hr,
.c-container__vr {
	position: absolute;
	z-index: 1;
	background-color: rgba(#000, 0.8);
}

.c-container__vr {
	top: 0;
	bottom: 0;
	width: 1px;

	&--l {
		left: tools.figma-to-vw(60);
	}

	&--r {
		right: tools.figma-to-vw(60);
	}
}

.c-container__hr {
	right: 0;
	left: 0;
	height: 1px;

	&--t {
		top: tools.figma-to-vw(60);
	}

	&--b {
		bottom: tools.figma-to-vw(60);
	}
}

.s-dark-theme {
	&.c-container {
		background: var(--primary-base-color);

		.c-container__hr,
		.c-container__vr {
			background-color: rgba(#FFF, 0.8);
		}
	}
}

