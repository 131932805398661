// Enable/Disable the utils that are being used

@use "../tools/index" as tools;

// =============================================================================
// inline
// =============================================================================
/**
 * Utility placed on a wrapping element to apply spacing on the left of each
 * underlying child element, except the first element.
 */
.u-inline {
	@include tools.generate-value(2, 3, 4, 5, 7, 16) using ($value) {
		& > * + * {
			margin-left: tools.figma-to-vw($value * 4);
		}
	}
}

/**
 * Utility placed on a wrapping element to apply spacing around each underlying
 * child element, except the first element. Used in combination with flex-wrap:
 * wrap.
 */
// .u-inline-wrap {
// 	@include tools.generate-value() using ($value) {
// 		$space: tools.figma-to-vw($value * 4);

// 		margin: $space * -1;

// 		& > * {
// 			margin: $space;
// 		}
// 	}
// }

// =============================================================================
// stack
// =============================================================================

/**
 * Utility placed on the wrapping element to apply spacing on the top of each
 * underlying child element, except the first element.
 */
.u-stack {
	@include tools.generate-value(3, 4, 8) using ($value) {
		& > * + * {
			margin-top: tools.figma-to-vw($value * 4);
		}
	}
}

// =============================================================================
// margin
// =============================================================================
.u-m {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(0, 1, 2, 4) using ($value) {
		margin: tools.figma-to-vw($value * 4);
	}
}

.u-mt {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(2, 8, 12, 18, 48) using ($value) {
		margin-top: tools.figma-to-vw($value * 4);
	}
}

.u-mb {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(1, 2, 4, 8, 10, 11, 40) using ($value) {
		margin-bottom: tools.figma-to-vw($value * 4);
	}
}

.u-ml {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(3, 6) using ($value) {
		margin-left: tools.figma-to-vw($value * 4);
	}
}

.u-mr {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(1, 2, 3, 6) using ($value) {
		margin-right: tools.figma-to-vw($value * 4);
	}
}

// .u-mx {
// 	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
// 	@include tools.generate-value() using ($value) {
// 		margin-right: tools.figma-to-vw($value * 4);
// 		margin-left: tools.figma-to-vw($value * 4);
// 	}
// }

// .u-mx-auto {
// 	margin-right: auto;
// 	margin-left: auto;
// }

.u-ml-auto {
	margin-left: auto;
}

// .u-my {
// 	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
// 	@include tools.generate-value() using ($value) {
// 		margin-top: tools.figma-to-vw($value * 4);
// 		margin-bottom: tools.figma-to-vw($value * 4);
// 	}
// }

// =============================================================================
// padding
// =============================================================================
.u-p {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(2, 4, 10) using ($value) {
		padding: tools.figma-to-vw($value * 4);
	}
}

.u-pt {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(1, 5, 18) using ($value) {
		padding-top: tools.figma-to-vw($value * 4);
	}
}

.u-pb {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(5, 9) using ($value) {
		padding-bottom: tools.figma-to-vw($value * 4);
	}
}

.u-pl {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(6, 10, 13) using ($value) {
		padding-left: tools.figma-to-vw($value * 4);
	}
}

.u-pr {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(6, 13) using ($value) {
		padding-right: tools.figma-to-vw($value * 4);
	}
}

.u-px {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(5, 9, 13, 15) using ($value) {
		// Create different spacings in steps of 4
		$size: tools.figma-to-vw(4) * $value;

		padding-right: $size;
		padding-left: $size;
	}
}

.u-py {
	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	@include tools.generate-value(9) using ($value) {
		padding-top: tools.figma-to-vw($value * 4);
		padding-bottom: tools.figma-to-vw($value * 4);
	}
}
