@use "../tools/index" as tools;

:root {
	// Borders
	--border-width: #{tools.figma-to-vw(1)};
	--border-style: solid;
	--border-base: var(--border-width) var(--border-style) var(--border-color);
	// Radius
	--radius-max: 100vmax;
	--radius-round: 50%;
	--radius-default: #{tools.figma-to-vw(4)};
	--radius-small: #{tools.figma-to-vw(2)};
	--radius-large: #{tools.figma-to-vw(20)};
}
