.ca-grid {
  border-bottom: 1px solid black;

  .grid-container {
    width: 100vw;
    height: 105vh;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: grid;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, 1fr);

    .grid-item {
      border: 1px solid black;
      background-color: transparent;
    }

    .overlay {
      display: flex;
      margin: 15px auto;
      width: 100%;
      height: 100%;
      max-width: calc(100% - 30px);
      max-height: calc(100% - 30px);
      position: relative;

      .viewfinder {
        border: 0 solid black;
        display: block;
        width: 100%;
        height: 100%;
        max-width: calc(var(--lls-ca-grid-unit) * 1.5);
        max-height: calc(var(--lls-ca-grid-unit) * 1.5);
        margin: auto;
        position: absolute;

        &.corner-top-left {
          border-left-width: var(--lls-ca-grid-border-thick);
          border-top-width: var(--lls-ca-grid-border-thick);
          left: 0;
          top: 0;
        }
        &.corner-top-right {
          border-right-width: var(--lls-ca-grid-border-thick);
          border-top-width: var(--lls-ca-grid-border-thick);
          right: 0;
          top: 0;
        }
        &.corner-bottom-right {
          border-right-width: var(--lls-ca-grid-border-thick);
          border-bottom-width: var(--lls-ca-grid-border-thick);
          right: 0;
          bottom: 0;
        }
        &.corner-bottom-left {
          border-left-width: var(--lls-ca-grid-border-thick);
          border-bottom-width: var(--lls-ca-grid-border-thick);
          left: 0;
          bottom: 0;
        }
      }
    }
  }

  .background-grid {
    z-index: 1;

    .grid-item-1 { grid-area: 1 / 1 / 2 / 5; }
    .grid-item-2 { grid-area: 2 / 1 / 3 / 5; }
    .grid-item-3 { grid-area: 3 / 1 / 4 / 5; }
    .grid-item-4 { grid-area: 4 / 1 / 5 / 5; }
    .grid-item-5 { grid-area: 5 / 1 / 6 / 5; }
    .grid-item-6 { grid-area: 1 / 1 / 6 / 2; }
    .grid-item-7 { grid-area: 1 / 2 / 6 / 3; }
    .grid-item-8 { grid-area: 1 / 3 / 6 / 4; }
    .grid-item-9 { grid-area: 1 / 4 / 6 / 5; }
  }

  .content-grid {
    z-index: 3;

    .grid-item-1 { grid-area: 1 / 1 / 4 / 3; }
    .grid-item-2 { grid-area: 3 / 3 / 5 / 5; }
    .grid-item-3 { grid-area: 5 / 1 / 6 / 3; }
  }

  .freeflow-item {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 3;
  }

  .ca-grid-canvas {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  .ca-main-canvas {
    z-index: 2;
  }
  .ca-cutout-canvas {
    z-index: 4;
  }
}
