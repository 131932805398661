// =============================================================================
// Internal colors
// =============================================================================
/**
 * Internal colors should never be used outside this file and are only defined
 * to assign their values to css variables below. Every value is named to its
 * closest color, the number after the color is the lightness value when using
 * hsl notation to easily name and diffirentiate.
 */
$neutral-100-rgb: #ffffff; // hsl(0, 0%, 100%)
$neutral-95-rgb: #f2f2f2; // hsl(0, 0%, 95%)
$neutral-77-rgb: #c4c4c4; // hsl(0, 0%, 77%)
$neutral-7-rgb: #111111; // hsl(0, 0%, 7%)
$neutral-0-rgb: #000000; // hsl(0, 0%, 0%)

$orange-61-rgb: #ff8439; // hsl(23, 100%, 61%)
$orange-50-rgb: #ff6b00; // hsl(25, 100%, 50%)
$orange-38-rgb: #c25200; // hsl(25, 100%, 38%)

$dark-orange-54-rgb: #ec4727; // hsl(10, 84%, 54%)

$light-orange-50-rgb: #ff9900; // hsl(36, 100%, 50%)

$green-38-rgb: #04c200; // hsl(119, 100%, 38%)

// =============================================================================
// Public colors
// =============================================================================

/**
 * Language:
 * - base: the base color of a small palette
 * - tint: a lighter version of the base color
 * - shade: a darker version of the base color
 * - contrast: the color used on top of the base/light/shade color
 * - contrast-high, contrast-mid, contrast-low: the color used on top of the
 *   base color, but separated into 3 versions to display a certain amount of
 *   emphasis or importance.
 * - Border: color used as the default border color
 * - Shadow: color used as the default shadow color
 */

:root {
	/**
	 * Primary: Main brand colors, should be used for all interactive elements.
	 * Avoid using for regular texts.
	 */
	--primary-base-color: #{$neutral-0-rgb};
	--primary-tint-color: #{$neutral-7-rgb};
	--primary-contrast-color: #{$neutral-100-rgb};

	/**
	 * Secondary: Main brand colors, but on a lower level compared to primary.
	 * Should be used for all interactive elements. Avoid using for regular
	 * texts.
	 */
	--secondary-base-color: #{$neutral-100-rgb};
	--secondary-shade-color: #{$neutral-77-rgb};
	--secondary-contrast-color: #{$neutral-0-rgb};

	// Accent 1: Colors to highlight some parts
	--accent-1-base-color: #{$orange-61-rgb};
	--accent-1-shade-color: #{$dark-orange-54-rgb};

	// Accent 2: Colors to highlight some parts
	--accent-2-base-color: #{$orange-50-rgb};
	--accent-2-shade-color: #{$orange-38-rgb};

	// Success: Colors used to indicate something positive
	--success-base-color: #{$green-38-rgb};
	--success-contrast-color: #{$neutral-100-rgb};

	// Warning: Colors used to indicate something unstable
	--warning-base-color: #{$light-orange-50-rgb};
	--warning-contrast-color: #{$neutral-100-rgb};

	// Default: Colors used on regular UI elements
	--background-base-color: #{$neutral-95-rgb};
	--background-alt-color: #{$neutral-100-rgb};
	--backdrop-color: #{rgba($neutral-0-rgb, 0.2)};
	--contrast-high-color: #{$neutral-0-rgb};
	--contrast-mid-color: #{$neutral-7-rgb};
	--contrast-low-color: #{$neutral-77-rgb};
	--border-color: #{$neutral-77-rgb};
	--shadow-color: #{rgba($neutral-0-rgb, 0.08)};
}

// Class used to indicate a darker scope.
.s-dark {
	// Primary
	--primary-base-color: #{$neutral-100-rgb};
	--primary-tint-color: #{$neutral-100-rgb};
	--primary-contrast-color: #{$neutral-0-rgb};
	// Default
	--background-base-color: #{$neutral-0-rgb};
	--background-alt-color: #{$neutral-7-rgb};
	--contrast-high-color: #{$neutral-100-rgb};
	--contrast-mid-color: #{$neutral-95-rgb};
	--contrast-low-color: #{$neutral-77-rgb};
}

// Class used to indicate usage of components and text on top of image.
.s-visual {
	// Default
	--background-base-color: transparent;
	--background-alt-color: transparent;
	--contrast-high-color: #{$neutral-100-rgb};
	--contrast-mid-color: #{$neutral-95-rgb};
	--contrast-low-color: #{$neutral-77-rgb};
}
