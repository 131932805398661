.TextBlock {
    position: absolute;
    z-index: 999999;

    &-codify {
        &.mode-dark {
            color: black;
        }

        &.mode-light {
            color: white;
        }
    }

    &-fade {
        font-family: Helvetica, Arial;
        line-height: 1.5;
        align-items: center;
        justify-content: center;
        display: flex;
        box-sizing: border-box;
        padding: 1.30208333vw;
        position: absolute;
        width: 100%;
        height: 100%;
        > div {
            width: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            span {
                display: block;
            }
        }
    }
}
