@use '../variables' as vars;

$title-font-size: 166px;
$title-line-height: 137px;
// this offset compensates the vertical offset due to different font size and line height
$title-vertical-offset: $title-font-size - $title-line-height;

$subtitle-font-size: 64px;
$subtitle-line-height: 83px;


.title-chapter {
    & > * {
        position: absolute;
        top: 0
    }
}

.title-animation-container {
    width: 100%;
    box-sizing: content-box;
    border-color: black;

    .title-grid {
        position: absolute;
        top: 0;
    }

    .title-container {
        @include vars.hoi-font;

        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: $title-vertical-offset 47px;
        font-size: $title-font-size;
        line-height: $title-line-height;

        > * {
            max-width: 100%;
        }

        .title-text {
            width: 100%;
        }
    }
}

.sub-title {
    @include vars.hoi-font;

    font-size: $subtitle-font-size;
    line-height: $subtitle-line-height;
    color: white;
    width: 100%;

    .sub-title-background {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: #FF2800;
    }

    .sub-title-container {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 56px;

        > * {
            max-width: 100%;
        }
    }
}
