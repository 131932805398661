.Preloader {
    position: fixed;
    top: 25px;
    left: 25px;
    z-index: 999;
    border: 5px solid #ddd;
    border-top: 5px solid #999;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);

    }
}

@keyframes fade {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0
    }
    100% {
        opacity: 1;
    }
}
