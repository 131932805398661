@use '../variables' as vars;

.wayfinding-chapter {
    & > * {
        position: absolute;
        top: 0;
        width: 100%;
    }

    .floors {
        @include vars.hoi-font;

        display: grid;

        .active-floor-container {
            position: absolute;
            width: 100%;
            background: black;
            color: white;

            .floor {
                height: 100%;

                .floor-description-background {
                    background: black;
                    border-color: white;
                }

                .floor-number {
                    background: black;
                }
            }

        }
    }
}
