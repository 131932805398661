* {
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'helvetica-neue-hoi';
  src: url('../assets/fonts/HelveticaNeueHOI.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}