.PodDiD {
    color: #fff;
    
    &-text {
        margin-left: 60px;
        width: 540px;
    }
    &-top {
        width: 25%;
        min-height: 3.3%;
        background: black;
        margin-bottom: 2px;
        border-radius: 6px;
        padding: 3px 0 20px 2px
    }
    &-bottom {
        width: 100%;
        padding: 3px 0 20px 2px;
        background: black;
        border-radius: 6px;
    }
}
