.centeredLogo {
    position: absolute;
    height: 65vh;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
}