.MediaBlock {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    &.inset-true {
        border: 0.37037037vw solid #fff;
        box-sizing: border-box;
    }

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
}
