#MANNEQUIN {
    background-color: #ffffff;
    
    .overlay-inner-wrapper {
        width: 82%;
            height: 82%;
    }
    .vertical-text h2{
        font-weight: 500;
    }

    .view-finder-marks {
        width: 92%;
        height: 90%;
    }

    .swatch-list {
        height: calc(100% - (var(--lls-cs-mannequin-unit) * 2));
        left: var(--lls-cs-mannequin-unit);
        transition: all 1s ease;

        .swatch-item {
            border-width: var(--lls-cs-mannequin-border-thick);
        }

        .swatch-group .swatch-item:not(:first-child) {
            border-top-width: 0;
            margin-top: 0;
        }
    }
}