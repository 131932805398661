@font-face {
 font-family: "HelveticaNeue";
 font-weight: normal;
 src: url("../fonts/HelveticaNeue-Medium.ttf");
}
@font-face {
  font-family: "HelveticaNeue";
  font-weight: bold;
  src: url("../fonts/HelveticaNeueBold.ttf");
 }
@font-face {
 font-family: "NeuePixelGrotesk";
 src: url("../fonts/NeuePixelGrotesk_Regular.woff");
}
@font-face {
 font-family: "NIKE_FUTURA";
 src: url("../fonts/NIKE_FUTURA_CN-XB.ttf");
}

@font-face {
  font-family: "NOTO_SANS";
  font-weight: normal;
  src: url("../fonts/NotoSans-Medium.ttf");
}

* { margin: 0; padding: 0; }

html, body {
  font-family: 'helvetica-neue-medium';
  padding: 0;
  margin: 0;
  background: white;
}

/*
  LARGE/NORMAL SIZE
 */
.chapter-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  overflow: hidden;

  display: none;

  &.active {
    display: block;
  }

  .live-app {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: hidden;
  }
}

.main-container, .overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  overflow: hidden;
}

.overlay-container {
  .corner-slot {
    display: block;
    width: calc(50% - 50px);
    height: calc(50% - 50px);
    position: absolute;
    transform-style: preserve-3d;
    z-index: 10;

    .content-slot {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 5;
      margin: 25px;
    }

    &.top-left {
      top: 50px;
      left: 50px;

      .viewfinder-mark, .content-slot {
        top: 0;
        left: 0;
      }
    }
    &.top-right {
      top: 50px;
      right: 50px;

      .viewfinder-mark, .content-slot {
        top: 0;
        right: 0;
      }
    }
    &.bottom-right {
      bottom: 50px;
      right: 50px;

      .viewfinder-mark, .content-slot {
        bottom: 0;
        right: 0;
      }
    }
    &.bottom-left {
      bottom: 50px;
      left: 50px;

      .viewfinder-mark, .content-slot {
        bottom: 0;
        left: 0;
      }
    }

    &.center-left, &.center-right {
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      // border: 1px dashed red;

      .content-slot {
        top: 0;
        bottom: 0;
      }
    }
    &.center-left {
      left: 50px;

      .content-slot {
        left: 0;
      }
    }
    &.center-right {
      right: 50px;

      .content-slot {
        right: 0;
      }
    }
  }
}

.app.has-marks .overlay-container {
  .corner-slot .viewfinder-mark {
    z-index: 10;
    position: absolute;
    width: 150px;
    height: 150px;
    border: 2px solid black;
  }
  .corner-slot.top-left .viewfinder-mark {
    border-right: 0;
    border-bottom: 0;
  }
  .corner-slot.top-right .viewfinder-mark {
    border-left: 0;
    border-bottom: 0;
  }
  .corner-slot.bottom-right .viewfinder-mark {
    border-left: 0;
    border-top: 0;
  }
  .corner-slot.bottom-left .viewfinder-mark {
    border-right: 0;
    border-top: 0;
  }
}

.corner-slot {
  .vertical-text {
    width: 100%;
    display: flex;
    margin: 10px 0;

    h2 {
      display: inline;
      writing-mode: tb-rl;
      font-size: 35px;
      font-weight: normal;
      transform: rotate(180deg);
      font-family: "HelveticaNeue";
    }
  }

  &.top-left .vertical-text {
    justify-content: flex-start;
    text-align: right;
  }
  &.top-right .vertical-text {
    justify-content: flex-end;
    display: inline-grid;
      // text-align: right;
  }
  &.bottom-right .vertical-text {
    justify-content: flex-end;
    text-align: left;
  }
  &.bottom-left .vertical-text {
    justify-content: flex-start;
    text-align: left;
  }
}

.app.light-mode {
  .overlay-container .corner-slot .viewfinder-mark {
    border-color: white;
  }

  .corner-slot .vertical-text h2 {
    color: white;
  }
}

.swatch-list, .vertical-text, .img-logo {
  position: absolute;

  &.top-left, &.bottom-left {
    left: 0;
  }
  &.top-right, &.bottom-right {
    right: 0;
  }
  &.top-left, &.top-right {
    top: 0;
  }
  &.bottom-left, &.bottom-right {
    bottom: 0;
  }
}

.swatch-list {
  ul {
    list-style: none;
  }
  li.swatch-item {
    width: 50px;
    height: 50px;
    border: 2px solid black;
    box-sizing: border-box;
    display: block;
  }
}

.img-logo {
  img {
    width: 120px;
  }
}

.centered-title {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  padding: 7.5%;

  h1 {
    line-height: 15rem;
    font-size: 18rem;
    text-align: center;
    width: 100%;
    margin: auto;

    &.futura {
      font-family: NIKE_FUTURA;
      font-weight: 800;
    }
    &.helvetica {
      font-family: "helvetica-neue-medium";
      font-weight: 500;
    }
  }
}

.img-background {
  width: 100%;
  height: 100%;
  display: block;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.placed-text {
  position: absolute;
  font: 500 30px "helvetica-neue-medium";
}
.placed-img {
  position: absolute;
  img {
    width: 100%;

    &.is-qr {
      min-width: 70px;
      transform: translateY(-10px);
      //image-rendering: pixelated;
    }
  }
}

.main-container {
  .LLS-canvas, .main-top-layer {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .LLS-canvas {
    z-index: 1;
  }
  .main-top-layer {
    z-index: 2;
  }
}

.LLS-gui {
  width: 333px;
  position: fixed;
  top: 1em;
  left: 1em;
  z-index: 555;
}

/*
 MEDIUM SIZE
 */
@media (max-width: 719px), (max-height: 639px) {
  .overlay-container {
    .corner-slot {
      width: calc(50% - 25px);
      height: calc(50% - 25px);
      .content-slot {
        margin: 15px;
      }
      &.top-left {
        top: 25px;
        left: 25px;
      }
      &.top-right {
        top: 25px;
        right: 25px;
      }
      &.bottom-right {
        bottom: 25px;
        right: 25px;
      }
      &.bottom-left {
        bottom: 25px;
        left: 25px;
      }
      &.center-left {
        left: 25px;
      }
      &.center-right {
        right: 25px;
      }
    }
  }
  .app.has-marks .overlay-container {
    .corner-slot .viewfinder-mark {
      width: 75px;
      height: 75px;
      border-width: 1px;
    }
  }
  .corner-slot {
    .vertical-text {
      margin: 5px 0;
      h2 {
        font-size: 25px;
      }
    }
    .swatch-list {
      li.swatch-item {
        border-width: 1px;
        width: 25px;
        height: 25px;
      }
    }
    .img-logo {
      img {
        width: 70px;
      }
    }
  }
  .centered-title {
    h1 {
      line-height: 7.5rem;
      font-size: 9rem;
    }
  }
}

/*
SMALL SIZE
 */
@media (max-width: 479px), (max-height: 359px) {
  .overlay-container {
    .corner-slot {
      width: calc(50% - 20px);
      height: calc(50% - 20px);
      .content-slot {
        margin: 15px;
      }
      &.top-left {
        top: 20px;
        left: 20px;
      }
      &.top-right {
        top: 20px;
        right: 20px;
      }
      &.bottom-right {
        bottom: 20px;
        right: 20px;
      }
      &.bottom-left {
        bottom: 20px;
        left: 20px;
      }
      &.center-left {
        left: 20px;
      }
      &.center-right {
        right: 20px;
      }
    }
  }
  .app.has-marks .overlay-container {
    .corner-slot .viewfinder-mark {
      width: 50px;
      height: 50px;
      border-width: 1px;
    }
  }
  .corner-slot {
    .vertical-text {
      margin: 3px 0;
      h2 {
        font-size: 15px;
      }
    }
    .swatch-list {
      li.swatch-item {
        width: 20px;
        height: 20px;
        border-width: 1px;
      }
    }
    .img-logo {
      img {
        width: 40px;
      }
    }
  }
  .centered-title {
    h1 {
      line-height: 5rem;
      font-size: 6rem;
    }
  }
}

.LLS-canvas.legacy {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.LLS-DebuggingModal {
  display: none;
  position: fixed;
  z-index: 8;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
span.LLS-DebuggingSpan {
  display: block;
  font-size: large;
  color: rgb(0, 0, 0);
}


