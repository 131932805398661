.GridCard {
    position: absolute;
    // border: 1px solid red;

    &-motion {
        // border: 1px solid green
        position: relative;
        width: 100%;
        height: 100%;
    }

    // * {
    //     color:black !important;
    // }
}