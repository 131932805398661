// Enable/Disable the utils that are being used

@use "../tools/index" as tools;

// =============================================================================
// grid
// =============================================================================
.u-grid {
	display: grid;
}

// =============================================================================
// cols
// =============================================================================
[class*="u-grid"] {
	&.u-cols {
		// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
		@include tools.generate-value(1, 2, 3, 4, 8) using ($value) {
			grid-template-columns: repeat($value, minmax(0, 1fr));
		}
	}

	&.u-cols-none {
		grid-template-columns: none;
	}
}

// =============================================================================
// col
// =============================================================================
[class*="u-grid"] > {
	.u-col {
		// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
		@include tools.generate-value(1, 2, 3, 4, 5, 6, 8) using ($value) {
			grid-column: span $value / span $value;
		}
	}

	// .u-col-auto {
	// 	grid-column: auto;
	// }

	// .u-col-full {
	// 	grid-column: 1 / -1;
	// }

	.u-col-start {
		// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
		@include tools.generate-value(1, 2, 3, 4, 5, 6, 8) using ($value) {
			grid-column-start: $value;
		}
	}

	// .u-col-start-auto {
	// 	grid-column-start: auto;
	// }

	.u-col-end {
		// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
		@include tools.generate-value(4) using ($value) {
			grid-column-end: $value;
		}
	}

	// .u-col-end-auto {
	// 	grid-column-end: auto;
	// }
}

// =============================================================================
// rows
// =============================================================================
[class*="u-grid"] {
	&.u-rows {
		// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
		@include tools.generate-value(2, 6, 18) using ($value) {
			grid-template-rows: repeat($value, minmax(0, 1fr));
		}
	}

	&.u-rows-none {
		grid-template-rows: none;
	}
}

// =============================================================================
// row
// =============================================================================
[class*="u-grid"] > {
	.u-row {
		// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
		@include tools.generate-value(1, 2, 3, 4, 6, 12) using ($value) {
			grid-row: span $value / span $value;
		}
	}

	// .u-row-auto {
	// 	grid-row: auto;
	// }

	// .u-row-full {
	// 	grid-row: 1 / -1;
	// }

	.u-row-start {
		// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
		@include tools.generate-value(1, 2, 3, 4, 14, 10, 14, 18) using ($value) {
			grid-row-start: $value;
		}
	}

	// .u-row-start-auto {
	// 	grid-row-start: auto;
	// }

	// .u-row-end {
	// 	// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
	// 	@include tools.generate-value() using ($value) {
	// 		grid-row-end: $value;
	// 	}
	// }

	// .u-row-end-auto {
	// 	grid-row-end: auto;
	// }
}

// =============================================================================
// gap
// =============================================================================
// [class*="u-grid"] {
// 	&.u-gap {
// 		// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
// 		@include tools.generate-value() using ($value) {
// 			gap: tools.figma-to-vw($value);
// 		}
// 	}

// 	&.u-col-gap {
// 		// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
// 		@include tools.generate-value() using ($value) {
// 			column-gap: tools.figma-to-vw($value);
// 		}
// 	}

// 	&.u-row-gap {
// 		// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
// 		@include tools.generate-value() using ($value) {
// 			row-gap: tools.figma-to-vw($value);
// 		}
// 	}
// }

// =============================================================================
// justify-items
// =============================================================================
// [class*="u-grid"] {
// 	&.u-ji {
// 		// start, end, center, stretch
// 		@include tools.generate-value() using ($value) {
// 			justify-items: $value;
// 		}
// 	}
// }

// =============================================================================
// justify-content
// =============================================================================
// [class*="u-grid"] {
// 	&.u-jc {
// 		// start, end, center, stretch, between, around, evenly
// 		@include tools.generate-value() using ($value) {
// 			@if ($value == between) {
// 				justify-content: space-between;
// 			} @else if ($value == around) {
// 				justify-content: space-around;
// 			} @else if ($value == evenly) {
// 				justify-content: space-evenly;
// 			} @else {
// 				justify-content: $value;
// 			}
// 		}
// 	}
// }

// =============================================================================
// justify-self
// =============================================================================
// [class*="u-grid"] > {
// 	.u-js {
// 		// start, end, center, stretch
// 		@include tools.generate-value() using ($value) {
// 			justify-self: $value;
// 		}
// 	}
// }

// =============================================================================
// align-items
// =============================================================================
[class*="u-grid"] {
	&.u-ai {
		// start, end, center, stretch
		@include tools.generate-value(center) using ($value) {
			align-items: $value;
		}
	}
}

// =============================================================================
// align-content
// =============================================================================
// [class*="u-grid"] {
// 	&.u-ac {
// 		// start, end, center, stretch, between, around, evenly
// 		@include tools.generate-value() using ($value) {
// 			@if ($value == between) {
// 				align-content: space-between;
// 			} @else if ($value == around) {
// 				align-content: space-around;
// 			} @else if ($value == evenly) {
// 				align-content: space-evenly;
// 			} @else {
// 				align-content: $value;
// 			}
// 		}
// 	}
// }

// =============================================================================
// align-self
// =============================================================================
// [class*="u-grid"] > {
// 	.u-as {
// 		// start, end, center, stretch
// 		@include tools.generate-value() using ($value) {
// 			align-self: $value;
// 		}
// 	}
// }

// =============================================================================
// place-items
// =============================================================================
// [class*="u-grid"] {
// 	&.u-pi {
// 		// start, end, center, stretch
// 		@include tools.generate-value() using ($value) {
// 			place-items: $value;
// 		}
// 	}
// }

// =============================================================================
// place-content
// =============================================================================
// [class*="u-grid"] {
// 	&.u-pc {
// 		// start, end, center, stretch, between, around, evenly
// 		@include tools.generate-value() using ($value) {
// 			@if ($value == between) {
// 				place-content: space-between;
// 			} @else if ($value == around) {
// 				place-content: space-around;
// 			} @else if ($value == evenly) {
// 				place-content: space-evenly;
// 			} @else {
// 				place-content: $value;
// 			}
// 		}
// 	}
// }

// =============================================================================
// place-self
// =============================================================================
// [class*="u-grid"] > {
// 	.u-ps {
// 		// start, end, center, stretch
// 		@include tools.generate-value() using ($value) {
// 			place-self: $value;
// 		}
// 	}
// }
