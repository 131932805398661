@use 'sass:math';

$red-color: #FF2800;

@mixin hoi-font {
    font-family: helvetica-neue-hoi;
    letter-spacing: -3.33px;
}

@function toNewBase($value, $base, $newBase) {
    @return math.div($value, $base) * $newBase;
}

@function to-vh($value, $base) {
    @return toNewBase($value, $base, 100vh);
}

@function to-vw($value, $base) {
    @return toNewBase($value, $base, 100vw);
}
