// Enable/Disable the utils that are being used

.u-fill-width {
	width: 100%;
}

.u-fill-height {
	height: 100%;
}

.u-fill-space {
	width: 100%;
	height: 100%;
}

.u-float-right {
	float: right;
}
