.NTCIntro {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    &-media {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
