.Atrium-Container {
	background: black;
	height: 100vh;
	width: 100vw;
}

.Atrium {
	$height: 56.25vw;
	background: white;
	height: $height;
	width: 100vw;

	position: relative;
	overflow: hidden;

	display: grid;
	grid-template-columns: 50vw 50vw;
	grid-template-rows: calc(56.25vw / 2) calc(56.25vw / 2);

	&-Segment {
		position: relative;

		height: 50%;
		display: grid;
		grid-template-columns: 25vw 25vw;
		grid-template-rows: calc(56.25vw / 4) calc(56.25vw / 4);

		video {
			height: 100%;
		}
	}
}
