@use "sass:list";

/**
 * Used to generate multiple classes based on the passed $values parameter. Each
 * value within $values is appended at the end of the wrapping parent class. The
 * value is passed as a parameter to the underlying content to be used as part
 * of the css definition.
 */
@mixin generate-value($values...) {
	@if ($values and list.length($values) > 0) {
		@each $value in $values {
			&-#{$value} {
				@content ($value);
			}
		}
	}
}
