#PDP-FULL-BODY  {
    background-color: #fafafa;
    .overlay-inner-wrapper {
        width: 70%;
        height: 87%;
    }
    .vertical-text h2{
        font-weight: 500;
        font-size: 3em;
    }

    .view-finder-marks {
        width: 84%;
        height: 94%;
    }

    .PDP-HEAD-TOE  {
        overflow: hidden;
    }
    .LLS-canvas {
        width: 101%;
        height: 101%;
        left: -1%;
        top: -1%;
    }

    .main-container {
        overflow: hidden;
    }
}