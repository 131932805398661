.cs-type {
  .grid-container {
    width: 100vw;
    height: 100vh;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: grid;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, 1fr);

    .grid-item {
      border: 1px solid black;
      background-color: transparent;
    }

    .grid-item-2 {
      text-align: right;
      border: 0;

      h2 {
        margin: 0.5em;
        display: block;
        font-size: 3.33em;
        font-weight: 500;
      }
    }
  }

  .is-vertical .grid-container {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }

  .freeflow-item .overlay {
    transition: all 1s ease;
  }

  .overlay {
    display: flex;
    margin: 15px auto;
    width: 100%;
    height: 100%;
    max-width: calc(100% - 30px);
    max-height: calc(100% - 30px);
    position: relative;

    .rangefinder {
      display: block;
      border: 1px solid black;
      border-radius: 50%;
      display: block;
      margin: auto;
      width: 100%;
      height: 100%;
      max-width: calc(var(--lls-cs-type-unit) * 4);
      max-height: calc(var(--lls-cs-type-unit) * 4);
      transition: all 1s ease;
    }

    .viewfinder {
      border: 0 solid black;
      display: block;
      width: 100%;
      height: 100%;
      max-width: calc(var(--lls-cs-type-unit) * 1.5);
      max-height: calc(var(--lls-cs-type-unit) * 1.5);
      margin: auto;
      position: absolute;

      &.corner-top-left {
        border-left-width: 1px;
        border-top-width: 1px;
        left: 0;
        top: 0;
      }
      &.corner-top-right {
        border-right-width: 1px;
        border-top-width: 1px;
        right: 0;
        top: 0;
      }
      &.corner-bottom-right {
        border-right-width: 1px;
        border-bottom-width: 1px;
        right: 0;
        bottom: 0;
      }
      &.corner-bottom-left {
        border-left-width: 1px;
        border-bottom-width: 1px;
        left: 0;
        bottom: 0;
      }
    }

    .swatch-list {
      height: calc(100% - var(--lls-cs-type-unit));
      left: 15px;
      transition: all 1s ease;
    }

    &.wide {
      margin: calc(var(--lls-cs-type-unit) * 1.25) auto;
      max-width: calc(100% - (var(--lls-cs-type-unit) * 2.5));
      max-height: calc(100% - (var(--lls-cs-type-unit) * 2.5));

      .rangefinder {
        max-width: calc(var(--lls-cs-type-unit) * 8);
        max-height: calc(var(--lls-cs-type-unit) * 8);
      }

      .swatch-list {
        height: calc(100% - (var(--lls-cs-type-unit) * 2.5));
        left: var(--lls-cs-type-unit);
      }
    }
  }

  .background-grid {
    z-index: 1;

    .grid-item-1 { grid-area: 1 / 1 / 2 / 7; }
    .grid-item-2 { grid-area: 2 / 1 / 3 / 7; }
    .grid-item-3 { grid-area: 3 / 1 / 4 / 7; }
    .grid-item-4 { grid-area: 4 / 1 / 5 / 7; }
    .grid-item-5 { grid-area: 1 / 1 / 5 / 2; }
    .grid-item-6 { grid-area: 1 / 2 / 5 / 3; }
    .grid-item-7 { grid-area: 1 / 3 / 5 / 4; }
    .grid-item-8 { grid-area: 1 / 4 / 5 / 5; }
    .grid-item-9 { grid-area: 1 / 5 / 5 / 6; }
    .grid-item-10 { grid-area: 1 / 6 / 5 / 7; }
  }

  &.is-vertical {
    .grid-item {
      border: none;
    }
    .content-grid .grid-item-1 {
      grid-area: 2 / 1 / 4 / 7;
    }
    .content-grid .grid-item-2 {
      display: none;
    }
  }

  .content-grid {
    z-index: 3;

    .grid-item-1 { grid-area: 2 / 2 / 4 / 6; }
    .grid-item-2 { grid-area: 4 / 5 / 5 / 6; }
  }

  .cs-type-canvas {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 2;
  }
}
