.AppLogo {
    position: relative;
    width: 100%;
    height: 100%;

    &.qr-true {
        background: #fff;
    }

    &-container {
        position: absolute;
        margin: 2px;
        width: 100%;
        height: 100%;
        background: #000;
        border-radius: 20px;
        overflow: hidden;

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 50%;
            height: 50%;
        }
    }

    .QR {
        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 50%;
            height: 50%;
        }

        &-headline {
            position: absolute;
            top: 1%;
            left: 1%;
        }
        &-subhead {
            position: absolute;
            bottom: 1%;
            left: 1%;
        }
    }
}
