.NTC {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    height: 100vh;
    width: 100vw;
    overflow: hidden;

    &.orientation-Portrait {
        height: 100vh;
        width: 56.25vh;
    }

    &-lottie {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
    }

    &-media {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &-videobg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        overflow: visible;
    }

    &-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        overflow: visible;
    }
}
