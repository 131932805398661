.ca-closeup {
  .overlay {
    display: flex;
    margin: 15px auto;
    width: 100%;
    height: 100%;
    max-width: calc(100% - 30px);
    max-height: calc(100% - 30px);
    position: relative;
    z-index: 3;

    .viewfinder {
      border: 0 solid black;
      display: block;
      width: 100%;
      height: 100%;
      max-width: calc(var(--lls-ca-closeup-unit) * 1.5);
      max-height: calc(var(--lls-ca-closeup-unit) * 1.5);
      margin: auto;
      position: absolute;

      &.corner-top-left {
        border-left-width: var(--lls-ca-closeup-border-thick);
        border-top-width: var(--lls-ca-closeup-border-thick);
        left: 0;
        top: 0;
      }
      &.corner-top-right {
        border-right-width: var(--lls-ca-closeup-border-thick);
        border-top-width: var(--lls-ca-closeup-border-thick);
        right: 0;
        top: 0;
      }
      &.corner-bottom-right {
        border-right-width: var(--lls-ca-closeup-border-thick);
        border-bottom-width: var(--lls-ca-closeup-border-thick);
        right: 0;
        bottom: 0;
      }
      &.corner-bottom-left {
        border-left-width: var(--lls-ca-closeup-border-thick);
        border-bottom-width: var(--lls-ca-closeup-border-thick);
        left: 0;
        bottom: 0;
      }
    }
  }

  .vertical-text h2 {
    font-weight: 500;
    text-transform: uppercase;
  }
  &.is-size-medium .vertical-text h2 {
    font-size: 22px;
  }
  &.is-size-small .vertical-text h2 {
    font-size: 15px;
  }

  .vt-top {
    top: var(--lls-ca-closeup-unit);
    right: var(--lls-ca-closeup-unit);
  }
  .vt-bottom {
    bottom: var(--lls-ca-closeup-unit);
    right: var(--lls-ca-closeup-unit);
  }

  .swatch-list {
    height: calc(100% - (var(--lls-ca-closeup-unit) * 2));
    left: var(--lls-ca-closeup-unit);
    transition: all 1s ease;

    .swatch-item {
      border-width: var(--lls-ca-closeup-border-thick);
    }

    .swatch-group .swatch-item:not(:first-child) {
      border-top-width: 0;
      margin-top: 0;
    }
  }

  .freeflow-item {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 1;
  }

  .ca-closeup-canvas {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  .ca-closeup-main-canvas {
    z-index: 1;
    top: -2px;
    transform: scale(1.05);
  }
  .ca-closeup-cutout-canvas {
    z-index: 2;
  }
}
