// Enable/Disable the utils that are being used

@use "../tools/index" as tools;

// =============================================================================
// font-family
// =============================================================================
.u-font-f {
	@include tools.generate-value(primary, secondary) using ($value) {
		font-family: var(--font-f-#{$value});
	}
}

// =============================================================================
// font-weight
// =============================================================================
.u-font-w {
	@include tools.generate-value(bold, medium, normal) using ($value) {
		font-weight: var(--font-w-#{$value});
	}
}

// =============================================================================
// font-size
// =============================================================================
.u-font-s {
	@include tools.generate-value(7xl, 6xl, 5xl, 4xl, 3xl, 2xl, xl, lg, md, sm, xs, 2sx, 3sx, 4xs) using ($value) {
		font-size: var(--font-s-#{$value});
	}
}

// =============================================================================
// line-height
// =============================================================================
.u-line-height {
	@include tools.generate-value(7xl, 6xl, 5xl, 4xl, 3xl, 2xl, xl, lg, md, sm, xs, 2sx, 3sx) using ($value) {
		line-height: var(--line-height-#{$value});
	}
}

.u-no-line-height {
	line-height: 0;
}

// =============================================================================
// text-align
// =============================================================================
.u-text-c {
	text-align: center;
}

.u-text-l {
	text-align: left;
}

.u-text-r {
	text-align: right;
}

// =============================================================================
// text-transform
// =============================================================================
.u-text-up {
	text-transform: uppercase;
}

// .u-text-low {
// 	text-transform: lowercase;
// }
