.ca-LTCC {
  .grid-container {
    width: 100vw;
    height: 100vh;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-repeat: repeat;
    background-position: 50vw 50%;
    background-size: calc(100vw/15) calc(100vh/20);
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI2LjMuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNTAgMTUwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNTAgMTUwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6IzAwMDAwMDtzdHJva2Utd2lkdGg6MjtzdHJva2UtbGluZWNhcDpzcXVhcmU7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO30KCS5zdDF7ZmlsbDpub25lO30KPC9zdHlsZT4KPGcgaWQ9IkxheWVyXzEtMiI+Cgk8bGluZSBjbGFzcz0ic3QwIiB4MT0iNzQuNCIgeTE9IjQ5LjUiIHgyPSI3NC40IiB5Mj0iNzQuNSIvPgoJPGxpbmUgY2xhc3M9InN0MCIgeDE9Ijc0LjQiIHkxPSI3NC41IiB4Mj0iNDkiIHkyPSI3NC41Ii8+Cgk8bGluZSBjbGFzcz0ic3QwIiB4MT0iNzQuNCIgeTE9Ijk5LjQiIHgyPSI3NC40IiB5Mj0iNzQuNSIvPgoJPGxpbmUgY2xhc3M9InN0MCIgeDE9Ijc0LjQiIHkxPSI3NC41IiB4Mj0iOTkuOSIgeTI9Ijc0LjUiLz4KCTxyZWN0IGNsYXNzPSJzdDEiIHdpZHRoPSIxNTAiIGhlaWdodD0iMTUwIi8+CjwvZz4KPC9zdmc+Cg==);

    display: grid;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-template-columns: repeat(15, 1fr);
    grid-template-rows: repeat(20, 1fr);

    .grid-item {
      background-color: transparent;
      // margin: 10px;
    }
    .grid-item-1 { grid-area: 2 / 2 / 6 / 5; }
    .grid-item-2 { grid-area: 4 / 15 / 6 / 12; }
    .grid-item-3 { grid-area: 13 / 15 / 15 / 13; }
    .grid-item-4 { grid-area: 12 / 2 / 14 / 5; }
  }

  .ca-LTCC-canvas {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  .ca-LTCC-main-canvas {
    z-index: 2;
  }
}
