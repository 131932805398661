.snikr-wrapper {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: none;
    background-color: white;
    overflow: hidden;
    z-index: 12;

    .view-finder-marks {
        width: 93%;
        height: 95%;
    }

    .swatch-list {
        height: 90%;
        margin-left: 8%;

        .swatch-item {
            border-width: 2px;

        }
    }

    .overlay {
        display: flex;
        margin: 15px auto;
        width: 100%;
        height: 100%;
        max-width: calc(100% - 30px);
        max-height: calc(100% - 30px);
        position: absolute;
        z-index: 3;
    }

    .vertical-text h2 {
        font-weight: 500;
        text-transform: uppercase;
        font-family: 'helvetica';
        font-weight: 500;
        font-size: 35px;
        writing-mode: tb-rl;
        font-weight: normal;
        display: inline;
        transform: rotate(180deg);
        text-orientation: sideways-right;
    }

    &.is-size-medium .vertical-text h2 {
        font-size: 22px;
    }

    &.is-size-small .vertical-text h2 {
        font-size: 15px;
    }

    .vt-top {
        top: var(--lls-ca-closeup-unit);
        right: var(--lls-ca-closeup-unit);
        margin-top: 50px;
        margin-right: 10px;
    }

    .vt-bottom {
        bottom: var(--lls-ca-closeup-unit);
        right: var(--lls-ca-closeup-unit);
        margin-bottom: 50px;
        margin-right: 10px;
    }
}