.QRCode {
    position: absolute;
    bottom: 1.30208333vh;
    left: 1.30208333vh;

    &-icons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &-icon {
        width: 5.20833333vh;
        height: 5.20833333vh;
        margin: 0 0.25vh 0 0;
        position: relative;
        overflow: hidden;
        padding: 0.2vh;
        background: #fff;
        border-radius: .5vh;
        // border-radius: 1.04166667vh;
    }

    &-cta {
        padding-left: 1vh;
        font-size: 1.45vh;
    }
}
