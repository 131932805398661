.PodLogo {
    background-color: black;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto !important;
        height: auto !important;
        max-width: 180px;
        width: 180px;
        min-width: 180px;
    }
}