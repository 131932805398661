.NBYPricing {
    position: fixed;
    width: 100vw;
    height: 56.25vw;
    
    &-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &-menu {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
}
