* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: palatino;
  src: url('/src/touchpoints/hoi/animations/ticker/static/res/fonts/Palatino-Regular.ttf');
}

@font-face {
  font-family: 'helvetica-neue';
  src: url('/src/touchpoints/hoi/animations/ticker/static/res/fonts/HelveticaNeue-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSerif';
  src: url('/src/touchpoints/core/assets/fonts/NotoSerifSC-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'NotoSans';
  src: url('/src/touchpoints/hoi/animations/ticker/static/res/fonts/NotoSansSC-Medium.otf') format('opentype');
}
