.gridded-imagery {
  .grid-container {
    width: 100vw;
    height: 100vh;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: grid;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, 1fr);

    .grid-item {
      border: 1px solid black;
      background-color: transparent;
    }
  }

  .is-vertical .grid-container {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }

  .freeflow-item .overlay {
    transition: all 1s ease;
  }

  .overlay {
    display: flex;
    margin: 15px auto;
    width: 100%;
    height: 100%;
    max-width: calc(100% - 30px);
    max-height: calc(100% - 30px);
    position: relative;

    .rangefinder {
      display: block;
      border: var(--lls-cs-grid-border-thick) solid black;
      border-radius: 50%;
      margin: auto;
      width: 100%;
      height: 100%;
      max-width: calc(var(--lls-cs-grid-unit) * 4);
      max-height: calc(var(--lls-cs-grid-unit) * 4);
      transition: all 1s ease;
    }

    .viewfinder {
      border: 0 solid black;
      display: block;
      width: 100%;
      height: 100%;
      max-width: calc(var(--lls-cs-grid-unit) * 1.5);
      max-height: calc(var(--lls-cs-grid-unit) * 1.5);
      margin: auto;
      position: absolute;

      &.corner-top-left {
        border-left-width: var(--lls-cs-grid-border-thick);
        border-top-width: var(--lls-cs-grid-border-thick);
        left: 0;
        top: 0;
      }
      &.corner-top-right {
        border-right-width: var(--lls-cs-grid-border-thick);
        border-top-width: var(--lls-cs-grid-border-thick);
        right: 0;
        top: 0;
      }
      &.corner-bottom-right {
        border-right-width: var(--lls-cs-grid-border-thick);
        border-bottom-width: var(--lls-cs-grid-border-thick);
        right: 0;
        bottom: 0;
      }
      &.corner-bottom-left {
        border-left-width: var(--lls-cs-grid-border-thick);
        border-bottom-width: var(--lls-cs-grid-border-thick);
        left: 0;
        bottom: 0;
      }
    }

    .swatch-list {
      height: calc(100% - var(--lls-cs-grid-unit));
      left: var(--lls-cs-grid-unit);
      transition: all 1s ease;

      .swatch-item {
        border-width: var(--lls-cs-grid-border-thick);
      }

      .swatch-group .swatch-item:not(:first-child) {
        border-top-width: 0;
        margin-top: 0;
      }
    }

    &.wide {
      margin: calc(var(--lls-cs-grid-unit) * 1.25) auto;
      max-width: calc(100% - (var(--lls-cs-grid-unit) * 2.5));
      max-height: calc(100% - (var(--lls-cs-grid-unit) * 2.5));

      .rangefinder {
        max-width: calc(var(--lls-cs-grid-unit) * 8);
        max-height: calc(var(--lls-cs-grid-unit) * 8);
      }

      .swatch-list {
        height: calc(100% - (var(--lls-cs-grid-unit) * 2.5));
        left: var(--lls-cs-grid-unit);
      }
    }
  }

  .background-grid {
    z-index: 1;

    .grid-item-1 { grid-area: 1 / 1 / 2 / 7; }
    .grid-item-2 { grid-area: 2 / 1 / 3 / 7; }
    .grid-item-2bis-vertical { display: none; }
    .grid-item-3 { grid-area: 3 / 1 / 4 / 7; }
    .grid-item-4 { grid-area: 4 / 1 / 5 / 7; }
    .grid-item-4bis-vertical { display: none; }
    .grid-item-5 { grid-area: 1 / 1 / 5 / 2; }
    .grid-item-6 { grid-area: 1 / 2 / 5 / 3; }
    .grid-item-7 { grid-area: 1 / 3 / 5 / 4; }
    .grid-item-8 { grid-area: 1 / 4 / 5 / 5; }
    .grid-item-9 { grid-area: 1 / 5 / 5 / 6; }
    .grid-item-10 { grid-area: 1 / 6 / 5 / 7; }
  }

  .is-vertical .background-grid {
    .grid-item-1 { grid-area: 1 / 1 / 2 / 7; }
    .grid-item-2 { grid-area: 2 / 1 / 3 / 7; }
    .grid-item-2bis-vertical { grid-area: 3 / 1 / 4 / 7; }
    .grid-item-3 { grid-area: 4 / 1 / 5 / 7; }
    .grid-item-4 { grid-area: 5 / 1 / 6 / 7; }
    .grid-item-4bis-vertical { grid-area: 6 / 1 / 7 / 7; }
    .grid-item-5 { grid-area: 1 / 1 / 7 / 2; }
    .grid-item-6 { grid-area: 1 / 2 / 7 / 3; }
    .grid-item-7 { grid-area: 1 / 3 / 7 / 4; }
    .grid-item-8 { grid-area: 1 / 4 / 7 / 5; }
    .grid-item-9 { display: none; }
    .grid-item-10 { display: none; }
  }

  .content-grid {
    z-index: 3;

    .grid-item-1 { grid-area: 1 / 2 / 3 / 4; }
    .grid-item-2 { grid-area: 4 / 3 / 5 / 5; }
    .grid-item-3 { grid-area: 2 / 6 / 4 / 7; }
  }

  .is-vertical .content-grid {
    .grid-item-1 { grid-area: 1 / 1 / 3 / 3; }
    .grid-item-2 { grid-area: 7 / 2 / 6 / 4; }
    .grid-item-3 { grid-area: 3 / 4 / 5 / 7; }
  }


  .freeflow-item {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 3;

    border: 1px solid black;
    transition: border-width 1s ease;

    &.wide {
      border-width: 3px;
    }
  }
  .is-size-small .freeflow-item.wide {
    border-width: 2px;
  }

  .is-size-medium .vertical-text h2 {
    font-size: 22px;
  }
  .is-size-small .vertical-text h2 {
    font-size: 15px;
  }

  .vt-top {
    top: var(--lls-cs-grid-unit);
    right: var(--lls-cs-grid-unit);
  }
  .vt-bottom {
    bottom: var(--lls-cs-grid-unit);
    right: var(--lls-cs-grid-unit);
  }

  .cs-grid-canvas {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 2;
  }
}
