.Fastlane {
  position: relative;
  overflow: hidden;

  &-text {
    position: absolute;
    top: 0;
    // border: 1px solid red;

    color: #fff;
    display: flex;
    margin-left: 20px;
    height: 200%;

    &-chapter {
      height: 50%;
      max-height: 50%;
      min-height: 50%;
      overflow-y: hidden;
      box-sizing: border-box;
      padding-top: 25px;
      overflow-x: hidden;
      position: relative;

      &.fixed {
        position: fixed;
      }

      .filling {
        //height: 100px;
      }

      .supertitle {
        margin: 0 0 10px 0;
        white-space: nowrap;
      }

      .product-name {
        margin: 0 0 10px 0;
        // width: 100%;
        min-width: 700px;
        overflow: visible;
        font-size: 80%;
      }

      .sport-pulse {
        margin: 0 0 10px 0;
        width: 600px;
      }

      .subcopy {
        // white-space: nowrap;
        max-width: 360px;
      }

      .App {
        display: flex;
        margin-top: 20px;
        &-icon {
          width: 60px;

          img {
            height: 50px;
            margin-right: 7px;
            image-rendering: crisp-edges;
          }

        }
        .subcopy {
          padding-top: 17px;
          padding-left: 72px;
        }
      }
    }

    &.COMPACT {
      margin-left: 0;

      .Fastlane-text-chapter {
        height: initial;
        max-height: initial;
        min-height: 50%;
        padding-top: 0px;
      }

      .supertitle, .product-name {
        margin: 0 0 12px 0;
        position: relative;
      }

      .subcopy {
        white-space: nowrap;
        margin-top: 10px;

        .is-chinese {
          * {
            top: 40%
          }
        }
      }

      .App-Icons {
        position: absolute;
        top: 140px;
        //bottom: 20px;
        height: 90px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        .App{
          min-width: 350px;
          display: flex;
          align-items: center;

          &-icon {
            display: flex;
          }

          &-Cta {
            //margin-left: 15px;
            //margin-top: 9px;
            margin-top: -4px;
          }
        }
        img {
          margin-right: 15px;
        }
      }
    }
  }
}

.FastlaneTicker {
  overflow: hidden;
  background: #000;
  color: #fff;
  display: flex;
  width: 1440px;
  height: 50px;
  z-index: 99999;
  justify-content: space-between;

  &-item {
    .icon {
      padding: 10px 0;
      img {
        height: 30px;
      }
    }
  }
}
