@use '../variables' as vars;

.services {
    @include vars.hoi-font;

    & > * {
        position: absolute;
        top: 0;
    }

    .service-list {
        display: grid;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
        align-items: center;
    }

    .service-lang-item {
        position: relative;
        width: 100%;
        display: grid;
        align-items: center;
        overflow: hidden;
        border-bottom-style: solid;

        &.active {
            position: absolute;
            top: 0;
            color: white;

            .service-item-background {
                background: vars.$red-color;
            }
        }

        & > * {
            position: relative;
        }
    }

    .service-item-background {
        position: absolute;
        top: 0;
        height: 100%;
        width: calc(100% + 1px);
        background: white;
        border-right: 1px solid black;
    }

    .service-item-title {
        width: 100%;
        font-size: 64px;
        line-height: 64px;
        letter-spacing: -0.24px;
        white-space: pre-wrap;
        padding: 40px 56px;
        overflow: hidden;
    }

    .service-item-description {
        width: 100%;
        font-size: 48px;
        line-height: 48px;
        letter-spacing: -0.704px;
        white-space: pre-wrap;
        padding: 40px 56px;
    }
}
