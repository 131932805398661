.mixed-title {
  display: block;
  position: absolute;
  width: 80%;
  padding: 7.5%;

  &.is-ticker {
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    h1 {
      text-align: center;
      width: 100%;
      margin: auto;
    }
  }

  .mixed-title-wrapper h1 p {
    display: block !important;
    clear: both !important;
  }

  h1, h2, p {
    font-weight: 500;
    &.bold {
      font-weight: 800;
    }
    &.futura {
      font-family: NIKE_FUTURA;
      line-height: 0.8;
    }
    &.helvetica {
      font-family: "HelveticaNeue";
    }
    &.neue-pixel-grotesk {
      font-family: "NeuePixelGrotesk";
    }
  }
}