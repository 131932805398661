#VIEWFINDER {
    .overlay-inner-wrapper {
        width: 79%;
        height: 66%;
    }

    h2 {
        font-weight: 500;
        font-size: 30px;
    }

    .view-finder-marks {
        width: 84%;
        height: 76%;
    }

    @media screen and (max-width: 1000px) {
        .view-finder-marks {
            width: 86%;
            height: 92%;

            .view-finder-mark {
                width: 80px !important;
                height: 80px !important;
            }
        }

        h2 {
            font-size: 35px;
        }

        .overlay-inner-wrapper {
            width: 72%;
            height: 80%;
        }

    }
}