#PDP-HEAD-TOE, #PDP-HEAD-TOE-2, #PDP-HEAD-TOE-3, #PDP-HEAD-TOE-4  {
    background-color: #fafafa;

    .overlay-inner-wrapper {
        width: calc(100% - 80px);
        height: calc(100% - 80px);
        margin: 40px;

        @media only screen and (min-width:  479px) {
            width: calc(100% - 120px);
            height: calc(100% - 120px);
            margin: 60px;
        
        }

        @media only screen and (min-width:  715px) {
            width: calc(100% - 200px);
            height: calc(100% - 200px);
            margin: 100px;
        
        }
      
    }

    .vertical-text h2{
        font-weight: 500;
        font-size: 2.5em;
    }

    .view-finder-marks {
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        margin: 20px;

        @media only screen and (min-width:  479px) {
            width: calc(100% - 60px);
            height: calc(100% - 60px);
            margin: 30px;
        }

        @media only screen and (min-width:  715px) {
            width: calc(100% - 100px);
            height: calc(100% - 100px);
            margin: 50px;
        
        }
    }


    .PDP-HEAD-TOE  {
        overflow: hidden;
    }
    .LLS-canvas {
        width: 101%;
        height: 101%;
        left: -1%;
        top: -1%;
    }

        .head-toe-cta.center-right {
            right: -12px;
        }
}