.view-finder-marks {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 2;

    .view-finder-mark {
        position: absolute;

        :nth-child(2) {
            right: 0;
        }
    }

    .view-finder-mark:nth-child(2) {
        right: 0;
        transform: rotate(90deg);
    }

    .view-finder-mark:nth-child(3) {
        right: 0;
        bottom: 0;
        transform: rotate(180deg);
    }

    .view-finder-mark:nth-child(4) {
        bottom: 0;
        transform: rotate(-90deg);
    }
}

