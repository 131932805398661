@use '../../../../styles/tools';

.c-level-block {
	position: relative;
	display: flex;
	flex-direction: column;
	background: #FFF;
	color: black;

	&::after {
		content: "";
		position: absolute;
		right: tools.figma-to-vw(-60);
		bottom: 0;
		left: tools.figma-to-vw(-60);
		z-index: 1;
		height: 1px;
		background-color: rgba(#000, 0.8);
	}

	&--active {
		color: #FFF;
	}
}

.c-date:has(.is-chinese) {

	.is-chinese {
		* {
			top: 43%;
		}
	}
}

.c-level-block__inner {
	padding: 0 tools.figma-to-vw(16);
	overflow: hidden;
	position: relative;
}

.c-level-block__title {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	overflow: hidden;

	&.has-icon {
		left: 12vw;
		width: calc(100% - 12vw);
	}

	.c-codification {
		width: 100%;
	}

	&--initial {
		color: #000;
	}

	&--active {
		position: relative;
		padding: tools.figma-to-vw(72) 0;
		color: #FFF;
	}
}

.c-level-block__description {
	width: 70%;
	padding-top: tools.figma-to-vw(42);
	color: #FFF;

	* {
		font-weight: 500 !important;
	}
}

.c-level-block__number {
	width: tools.figma-to-vw(240);
	border-right-width: 1px;
	border-right-style: solid;
	font-size: tools.figma-to-vw(360);
	font-weight: 400;
	line-height: .86;
	text-align: center;
	white-space: pre;

	&.wide {
		text-align: left;
		padding-right: tools.figma-to-vw(20);
		padding-left: tools.figma-to-vw(20);
		width: tools.figma-to-vw(400);
	}

	&.chinese {
		line-height: .92;
		font-size: tools.figma-to-vw(300);
		padding-top: tools.figma-to-vw(18);
	}
}

.c-level-block__content {
	position: relative;
	flex: 1 0 auto;
	overflow: hidden;
}

.c-level-block__list-initial,
.c-level-block__list-active {
	position: absolute;
	width: 100%;
	padding-left: tools.figma-to-vw(90);
	font-weight: 800;
}

.c-level-block__list-initial {
	padding-top: tools.figma-to-vw(12);
	font-size: tools.figma-to-vw(28);
	color: #000;
}

.c-container {
	.is-chinese * {
		font-family: 'NOTO_SANS' !important;
		line-height: 105% !important;
		font-size: 90%;
		margin-top: -5%;
	}
}

.c-level-block__list-active {
	padding-top: tools.figma-to-vw(24);
	font-size: tools.figma-to-vw(60);
	color: #FFF;
}

.c-level-block__list-item {
	width: 100%;

	&.hidden {
		opacity: 0;
	}
}

.c-level-block__sub-list {
	margin-top: tools.figma-to-vw(-16);
	padding-bottom: tools.figma-to-vw(20);

	.c-codification {
		line-height: 0.8;
	}
}

.c-level-block__image {
	background-position: center;
	background-size: cover;
	width: 100%;
}

.c-level-block--with-legend {
	.c-level-block__inner {
		display: flex;
	}
}

.c-level-block--with-image {
	.c-level-block__title {
		&--active {
			padding: tools.figma-to-vw(27) 0 tools.figma-to-vw(54);
		}
	}

	.c-level-block__description {
		padding-top: tools.figma-to-vw(21);
	}
}

.s-dark-theme {
	.c-level-block {
		background: #000;
		color: #FFF;

		&::after {
			background-color: rgba(#FFF, 0.8);
		}
	}

	.c-level-block__title {
		&--initial {
			color: #FFF;
		}
	}

	.c-level-block__list-initial {
		color: #FFF;
	}
}
