.App {
    &.active {
        z-index: 9999;
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        border: solid 20px #BAFD02;
        // background-color: rgba(255, 0, 0, 0.25);
    }

}