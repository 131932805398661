

@font-face {
 font-family: "HelveticaNeue";
 src: url("../fonts/HelveticaNeue.ttc");
}

@font-face {
  font-family: "CHINA_FUTURA";
  src: url("../fonts/CHINA_FUTURA.otf");
 }
@font-face {
 font-family: "NeuePixelGrotesk";
 src: url("../fonts/NeuePixelGrotesk_Regular.woff");
}
@font-face {
 font-family: "NIKE_FUTURA";
 src: url("../fonts/NIKE_FUTURA_CN-XB.ttf");
}

* { margin: 0; padding: 0; }

html, body {
  font-family: 'helvetica-neue-medium';
  padding: 0;
  margin: 0;
}

canvas.LLS-canvas {
  display: block;
  width: 100vw;
  height: 100vh;
}
canvas.LLS-canvas.vertical {
  /*max-width: 480px;*/
  /*max-height: 1080px;*/
  /*margin: auto;*/
}

.LLS-gui {
  width: 333px;
  position: fixed;
  top: 1em;
  left: 1em;
  z-index: 10;
}

body::-webkit-scrollbar {
  display: none;
}
