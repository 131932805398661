.NBYMenuItem {
    position: relative;
    width: 80%;
    font-family: "Helvetica";
    font-size: 2.5vw;
    line-height: 2.8vw;

    color: black;
    padding: 1vw;
    &-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        span {
            display: block;
            opacity: 0.6;
        }

        h1 {
            font-weight: normal;
            padding-bottom: 0.5vw;
        }

        h2 {
            font-weight: normal;
            font-size: 2vw;
        }
    }
}
