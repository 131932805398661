.Headline {
    position: relative;
    &-container {
        position: absolute;
        padding-left: 5px;
        padding-top: 22px;
        width: 600px;
    }
    * {
        color: #fff;
    }
}
