@font-face {
	font-family: "HelveticaNeue";
	font-weight: normal;
	src: url("../../fonts/HelveticaNeue.ttc");
}
@font-face {
	font-family: "NeuePixelGrotesk";
	src: url("../../fonts/NeuePixelGrotesk_Regular.woff");
}
@font-face {
	font-family: "NIKE_FUTURA";
	src: url("../../fonts/NIKE_FUTURA_CN-XB.ttf");
}
@font-face {
  font-family: "noto";
  font-weight: normal;
  src: url("../../fonts/NotoSans-Medium.ttf");
}
