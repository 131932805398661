@use 'variables' as vars;
@use 'fonts' as *;

.hoi-font {
    @include vars.hoi-font;
}


.ticker {
    background: vars.$red-color;
    color: white;
    font-size: 56px;
    line-height: 73px;
}
