.chapter-L365 {
  .overlay-inner-wrapper {
      width: 85%;
        height: 90%;
@media (min-width: 1100px) {
    width: 92%;
    height: 85%;
  }
      }

.vertical-text {
  h2 {
      text-orientation: sideways-right;
  }
}

.top-right {
  min-height: 90px;
}
.img-logo {
  top: -8px;
  position: relative;
  left: -5px;

  img {
    width: 120px;
    }
    }


.rangeFinder {
  z-index: 2;
  max-width: initial !important;
  max-height: initial !important;
}

.view-finder-marks {
    width: 94%;
    height: 96%;
    @media (min-width: 1100px) {
        width: 97%;
        height: 95%;
      }
}
  .vertical-text h2{
    font-weight: 500;
  }
  .swatch-list ul {
    flex-direction: column-reverse;
  }

  .mixed-title {
    width: 100%;
    padding: 7.5%;
    display: block;
    position: absolute;
    height: 88%;
  }

  .mixed-title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }
}

.light-mode {
  .view-finder-mark {
      border-color: white !important;
  }
}

.chapter-L365.catalog-apparel {
.vertical-text.top-right {
    top: -10px;
  }
  .img-logo {
    top: -8px;
    position: relative;
    left: -5px;

    img {
      width: 80px;


    }
  }

}