html,
body {
  margin: 0;
  background-color: #111111;
  font-family: "NeueHelveticaforHOI";
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
}

#snkrs-calendar-canvas {
  height: 100vh;
  width: auto;
  display: inline-block;
}

@font-face {
  font-family: "NeueHelveticaforHOI";
  src: url("../assets/fonts/NeueHelveticaforHOIMedium.woff") format("woff");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

body.timeline {
}
body.timeline .square {
  position: relative;
  width: 200px;
  height: 200px;
  background-color: #ff0000;
}

body.timeline .buttons {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 200px;
  display: inline-block;
}
