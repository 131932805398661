.swatch-list {
  display: flex;
  height: 100%;
  align-self: center;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
  
  ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
  }

  .swatch-item {
    border: 1px solid black;
    margin-top: -1px;
  }
} 

.swatch-group {
  position: relative;
}

.swatch-list {
  .swatch-item {
    border-width: 1px;
  }

  .swatch-group .swatch-item:not(:first-child) {
    border-top-width: 0;
    margin-top: 0;
  }
}