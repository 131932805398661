.text-iteration {
    display: inline-block;
    position: relative;

    .text-iteration-target {
        opacity: 0;
    }

    .text-iteration-current {
        position: absolute;
        left: 0;
    }
}
