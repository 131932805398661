.NTCFullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &-media {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    &-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    p.paragraph {
        font-family: "helvetica-neue-medium";
        display: block;
        padding: 1.30208333vh 3vh 1.30208333vh 1.30208333vh;
        font-size: 1.6vh;
        line-height: 1.8vh;
    }
}
