// .Fastlane {
// 	position: relative;
// 	overflow: hidden;
// }

.ticker-pixelmap {
	overflow: hidden;
	position: absolute;
}

.ticker-wrapper {
	background: black;
	position: absolute;
	.inner-ticker-wrapper {
		position: relative;
		overflow: hidden;
	}
}

.ticker-container {
	position: absolute;
	width: 100%;
	background: #000;
	color: #fff;

	&.invert {
		background: #fff !important;
		color: #000 !important;
		.FastlaneTicker {
			background: #fff !important;
			color: #000 !important;
		}
		.icon svg path {
			fill: black !important;
		}

		.icon svg rect {
			fill: black !important;
		}
	}
}

.FastlaneTicker {
	height: 100%;
	display: inline-grid;
	grid-template-columns: auto 1fr;
	grid-auto-flow: column;
	align-items: center;
	position: relative;
	.text {
		box-sizing: border-box;
		white-space: nowrap;
		position: relative;
		overflow: hidden;
		max-height: 100%;
	}
	.children-container {
		display: flex;
		justify-content: space-evenly;
		align-items: center;

		&.left-align {
			justify-content: flex-start;
		}
	}
	.icon {
	}
}
