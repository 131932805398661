
.NSILVideo {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 56.25vh;

    video {
        height: 100%;
        width: 56.25vh;
        object-fit: cover;
        object-position: center center;

        &.landscape {
            height: unset;
            width: 100%;
        }
    }
}
