.snkrs-grid {
    position: absolute;
    display: flex;
    width: 100%;
    min-height: 100%;
    background-color: white;
    top: 0;
    left: 0;
    z-index: 10;
    flex-wrap: wrap;
}


.snkrs-item {
    position: relative;
    width: 49.8%;
    min-height: 566px;
    border-bottom: 3px solid black;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;

    img {
        width: 80%;
        min-height: 120px;
        margin: auto;
        display: block;
    }

    .snkrs-textblock {
        text-transform: uppercase;
        width: 75%;
            margin: 0 8% 8%;
            font-family: 'helvetica';
            font-size: 1.4em;
            font-weight: 100;
        h3 {
                margin: 0;
            }
    }
}

.snkrs-item-small {
    width: 100%;
    min-height: 1038px;

    .snkrs-textblock {
        text-transform: uppercase;
        width: 75%;
        margin: 0 8% 8%;
        font-family: 'helvetica';
        font-size: 2.3em;
        font-weight: 100;

        h3 {
            margin: 0;
        }
    }
}

.snkrs-item:nth-child(odd) {
    border-right: 3px solid black;
}
.snkrs-item-small:nth-child(odd) {
    border-right: 0;
}
.swatch-list-snkrs {
    width: 35px;
    position: absolute;
    top: 8%;
        left: 7%;
    list-style: none;
    display: inline-block;

    .swatch-item {
        width: 35px;
        height: 35px;
        border-right: 3px solid black;
        border-left: 3px solid black;
        border-top: 3px solid black;
    }

    .swatch-item:last-child {
        border-bottom: 3px solid black;
    }

    .red {
        background-color: red;
    }

    .black {
        background-color: black;
    }
}